* {
  margin: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow: hidden; */
}

body {
  background: #fbfbfb;
  font-family: "Open Sans";
}

hr {
  margin-top: 5px;
  margin-bottom: 5px;
}

.progress {
  height: 5px;
}

.progress-bar {
  transition: width 0.5s ease-in-out; /* Animação personalizada */
}

.side p {
  text-align: center;
  margin: 0;
  padding: 0;
}

.side-card {
  border: solid 1px rgba(0, 0, 0, 0.175);
  border-radius: 5px;
  height: 46px;
  background: rgba(1, 84, 49, 0.2);
  margin: 0;
  padding: 0;
}

.button-exit {
  position: fixed;
  background: transparent;
  z-index: 9999;
}

@keyframes side-card-blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
  }
}

.contain {
  width: 98%;
  margin-left: auto;
  margin-right: auto;
}

.contain-img {
  width: 100%;
  height: 100vh;
  margin-top: 0px;
  margin-left: auto;
  margin-right: auto;
  background-image: url("./img/loginWeb.jpg");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.side-card-blink {
  -webkit-animation: side-card-blink 1s linear infinite;
  -moz-animation: side-card-blink 1s linear infinite;
  -ms-animation: side-card-blink 1s linear infinite;
  -o-animation: side-card-blink 1s linear infinite;
  animation: side-card-blink 1s linear infinite;
  border: solid 1px rgba(0, 0, 0, 0.175);
  border-radius: 5px;
  height: 33px;
  background: #FA6157;
}

.card-h6-barchart {
  border-radius: 5px;
  padding: 4px !important;
  margin-bottom: 5px;
  margin-top: 0px;
  font-size: 17px;
  text-align: center;
  background: #539a73;
  padding: 5px;
  color: white;
}

.inputdata {
  border-radius: 5px;
  border: solid 1px grey;
  padding: 3px 5px 2px 5px;
  font-size: 13px;
}

.button {
  background: rgba(27, 93, 67, 0.8);
  padding: 2px 15px 1px 15px;
  border-radius: 5px;
  margin-left: 10px;
  color: #F7FAFC;
  border: solid 0px gray;
}

.button:hover {
  background: rgba(21, 67, 48, 0.8);
}

.carousel-indicators,
.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: none;
}

.card-dados-sensor {
  border: solid 1px grey;
}

.col-sensor {
  background: rgba(1, 84, 49, 0.2);
  height: 80px;
  width: 80px;
  border-radius: 90px;
  padding-top: 19px;
  margin-left: auto;
  margin-right: auto;
  border: solid #539a73 1px;
}

.sens-0,
.sens-1,
.sens-2,
.sens-3,
.sens-4,
.sens-5 {
  background: #539a73;
  color: #ffffff;
  height: 60px;
  width: 60px;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
}

.sens-0 {
  margin-left: 5%;
  margin-top: 570px;
}

.sens-1 {
  margin-left: 80%;
  margin-top: 540px;
}

.sens-2 {
  margin-left: 5%;
  margin-top: 370px;
}

.sens-3 {
  margin-left: 80%;
  margin-top: 340px;
}

.sens-4 {
  margin-left: 5%;
  margin-top: 170px;
}

.sens-5 {
  margin-left: 80%;
  margin-top: 140px;
}

.metrics-0,
.metrics-1,
.metrics-2,
.metrics-3,
.metrics-4,
.metrics-5 {
  position: fixed;
  width: 210px;
  padding: 6px;
  border-radius: 5px;
  background-color: #ffffffe5;
  color: #286632;
  border: solid 1px #286632;
  box-shadow: 5px 5px;
}

.update {
  width: 170px;
  padding: 6px;
  border-radius: 5px;
  background-color: #ffffffe5;
  color: #286632;
  border: solid 1px #286632;
  box-shadow: 5px 5px;
}

.metrics-0 p,
.metrics-1 p,
.metrics-2 p,
.metrics-3 p,
.metrics-4 p,
.metrics-5 p {
  margin-bottom: 0;
}

.metrics-0 {
  margin-top: 7vh;
  margin-left: 14vw;
}

.metrics-1 {
  margin-top: 44vh;
  margin-left: 42vw;
}

.metrics-2 {
  margin-top: 82vh;
  margin-left: 41vw;
}

.metrics-3 {
  margin-top: 7vh;
  margin-left: 60vw;
}

.metrics-4 {
  margin-top: 44vh;
  margin-left: 87vw;
}

.metrics-5 {
  margin-top: 82vh;
  margin-left: 86vw;
}

.equip-0,
.equip-1,
.equip-2,
.equip-3,
.equip-4,
.equip-5 {
  position: fixed;
  z-index: 999;
}

.equip-0 {
  margin-top: 7vh;
  margin-left: 27vw;
}

.equip-1 {
  margin-top: 44vh;
  margin-left: 36vw;
}

.equip-2 {
  margin-top: 82vh;
  margin-left: 35vw;

}

.equip-3 {
  margin-top: 7vh;
  margin-left: 73vw;
}

.equip-4 {
  margin-top: 44vh;
  margin-left: 81vw;
}

.equip-5 {
  margin-top: 82vh;
  margin-left: 80vw;
}

.div-img-silo {
  height: 100vh;
  width: 100vw;
  background-size: cover;
}

.humidity {
  font-size: 21px;
  font-weight: 700;
}

.temperature {
  font-size: 16px;
  font-weight: 700;
}

.image-shadow {
  -webkit-filter: drop-shadow(0px 2px 2px rgba(0 0 0 / 90%));
  filter: drop-shadow(0px 2px 2px rgba(0 0 0 / 90%));
}

.seta-0,
.seta-1,
.seta-2,
.seta-3,
.seta-4,
.seta-5 {
  position: fixed;
  width: 60px;
  padding: 0px;
  border-radius: 5px;
  background-color: #286632;
  color: #286632;
  border: solid 2px #286632;
}

.seta-0 {
  margin-top: 11vh;
  margin-left: 25vw;
}

.seta-1 {
  margin-top: 48vh;
  margin-left: 40vw;
}

.seta-2 {
  margin-top: 86vh;
  margin-left: 39vw;
}

.seta-3 {
  margin-top: 11vh;
  margin-left: 71vw;
}

.seta-4 {
  margin-top: 48vh;
  margin-left: 85vw;
}

.seta-5 {
  margin-top: 86vh;
  margin-left: 84vw;
}

@keyframes infiniteScroll {
  from {
    transform: translateX(0)
  }

  to {
    transform: translateX(-50%)
  }
}

.containers {
  width: 100%;
  overflow-x: hidden;
}

.horizontal-scrolling-items {
  display: flex;
  font-size: 22px;
  width: 8920px;
  color: #286632;
  animation-name: infiniteScroll;
  animation-duration: 50s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.horizontal-scrolling-items__item {
  white-space: nowrap;
}


@media screen and (min-width: 900px) and (max-width: 1350px) {
  .metrics-0 {
    margin-top: 20vh;
    margin-left: 5vw;
  }

  .metrics-1 {
    margin-top: 44vh;
    margin-left: 43vw;
  }

  .metrics-2 {
    margin-top: 70vh;
    margin-left: 43vw;
  }

  .metrics-3 {
    margin-top: 20vh;
    margin-left: 53vw;
  }

  .metrics-4 {
    margin-top: 44vh;
    margin-left: 79vw;
  }

  .metrics-5 {
    margin-top: 70vh;
    margin-left: 79vw;
  }

  .equip-0 {
    margin-top: 20vh;
    margin-left: 25vw;
  }

  .equip-1 {
    margin-top: 44vh;
    margin-left: 33vw;
  }

  .equip-2 {
    margin-top: 70vh;
    margin-left: 33vw;
  }

  .equip-3 {
    margin-top: 20vh;
    margin-left: 73vw;
  }

  .equip-4 {
    margin-top: 44vh;
    margin-left: 70vw;
  }

  .equip-5 {
    margin-top: 70vh;
    margin-left: 70vw;
  }



  .seta-0 {
    margin-top: 24vh;
    margin-left: 22vw;
  }

  .seta-1 {
    margin-top: 48vh;
    margin-left: 39vw;
  }

  .seta-2 {
    margin-top: 74vh;
    margin-left: 39vw;
  }

  .seta-3 {
    margin-top: 24vh;
    margin-left: 70vw;
  }

  .seta-4 {
    margin-top: 48vh;
    margin-left: 75vw;
  }

  .seta-5 {
    margin-top: 74vh;
    margin-left: 75vw;
  }
}


@media screen and (min-width: 1350px) and (max-width: 1600px) {
  .metrics-0 {
    margin-top: 12vh;
    margin-left: 10vw;
  }

  .metrics-1 {
    margin-top: 44vh;
    margin-left: 41vw;
  }

  .metrics-2 {
    margin-top: 79vh;
    margin-left: 41vw;
  }

  .metrics-3 {
    margin-top: 12vh;
    margin-left: 57vw;
  }

  .metrics-4 {
    margin-top: 44vh;
    margin-left: 84vw;
  }

  .metrics-5 {
    margin-top: 79vh;
    margin-left: 84vw;
  }


  .equip-0 {
    margin-top: 12vh;
    margin-left: 26vw;
  }

  .equip-1 {
    margin-top: 44vh;
    margin-left: 34vw;
  }

  .equip-2 {
    margin-top: 79vh;
    margin-left: 34vw;
  }

  .equip-3 {
    margin-top: 12vh;
    margin-left: 73vw;
  }

  .equip-4 {
    margin-top: 44vh;
    margin-left: 77vw;
  }

  .equip-5 {
    margin-top: 79vh;
    margin-left: 77vw;
  }

  .seta-0 {
    margin-top: 16vh;
    margin-left: 23vw;
  }

  .seta-1 {
    margin-top: 48vh;
    margin-left: 39vw;
  }

  .seta-2 {
    margin-top: 83vh;
    margin-left: 39vw;
  }

  .seta-3 {
    margin-top: 16vh;
    margin-left: 70vw;
  }

  .seta-4 {
    margin-top: 48vh;
    margin-left: 82vw;
  }

  .seta-5 {
    margin-top: 83vh;
    margin-left: 82vw;
  }
}





@media (max-width: 650px) {
  .class-grafico {
    display: none;
  }
}



.reload-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #1C4220;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}